import React, { useState, useEffect } from "react";
import { Bars } from "react-loader-spinner";
import { Link } from "react-router-dom";

const LoaderBuyPage = ({ setLoading }) => {
  const [sendingTickets, setSendingTickets] = useState(false);
  useEffect(() => {
    // Second part - trigger after 3 seconds
    const timeout = setTimeout(() => {
      setSendingTickets(true); // Set sendingTickets to true after 3 seconds
    }, 17000);

    return () => clearTimeout(timeout);
  }, []);
  return (
    <>
      <div className="global-loader">
        <div className="wrapper">
          <div className="big-flex">
            {!sendingTickets ? (
              <Bars
                height="80"
                width="80"
                color="#fff"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="loader"
              />
            ) : (
              <div className="global-loader-text">
                Pričekajte formu za plaćanje...
                <br />
                Ukoliko ste izvršili plaćanje, pričekajte odgovor...
                <div className="loader-wrapper">
                  <Bars
                    height="80"
                    width="80"
                    color="#fff"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass="loader"
                  />
                </div>
                Provjerite vaš email ("Sva pošta"). Ako ste dobili izvještaj o uspješnoj
                transakciji, a unutar 10 min ne dobijete ulaznice, prijavite problem na
                <strong> info@event.ba </strong>.
              </div>
            )}
          </div>

          <div className="check-email">
            <Link to="/" style={{ color: "#fff" }}>
              Natrag na početnu
            </Link>
          </div>
        </div>
        <button disabled={!sendingTickets} onClick={() => setLoading(false)}>
          {!sendingTickets ? "Pričekaj" : "Zatvori"}
        </button>
      </div>
    </>
  );
};

export default LoaderBuyPage;
