import React from "react";
import FailedImg from "../../assets/images/failed.svg";
import { Link, useLocation } from "react-router-dom";

export const Failed = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const errorMessage = searchParams.get("errorMessage");

  return (
    <div className="container failed-container">
      <img src={FailedImg} alt="Failed" />
      <h3>Došlo je do greške pri kupovini karte! {errorMessage}</h3>
      <Link to="/">Natrag na početnu</Link>
    </div>
  );
};
